@font-face {
  font-family: "jonahdigitalicons";
  src: url("../fonts/jonahdigitalicons.ttf?uwqlyj") format("truetype"), url("../fonts/jonahdigitalicons.woff?uwqlyj") format("woff"), url("../fonts/jonahdigitalicons.svg?uwqlyj#jonahicons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=jdi-]::before,
[class*=" jdi-"]::before {
  font-family: "jonahdigitalicons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.jdi-cookie_banner:before {
  content: "\e90d";
}

.jdi-dynamic_lead_source_tracking:before {
  content: "\e90e";
}

.jdi-pricing_availability_integration:before {
  content: "\e90f";
}

.jdi-interactive_community_map:before {
  content: "\e912";
}

.jdi-jonah_dni:before {
  content: "\e913";
}

.jdi-skylease_upgrade:before {
  content: "\e914";
}

.jdi-skylease_additional_building:before {
  content: "\e915";
}

.jdi-third_party_availability_embed:before {
  content: "\e916";
}

.jdi-community_listing_replication:before {
  content: "\e917";
}

.jdi-premium_theme_design_swap:before {
  content: "\e918";
}

.jdi-multiple_integration_feeds:before {
  content: "\e919";
}

.jdi-property_integration_provider_change:before {
  content: "\e91a";
}

.jdi-floorplan_pdfs:before {
  content: "\e91b";
}

.jdi-floorplans_featured:before {
  content: "\e91c";
}

.jdi-website_theme:before {
  content: "\e91d";
}

.jdi-logo_redraw:before {
  content: "\e91e";
}

.jdi-design_skinning:before {
  content: "\e91f";
}

.jdi-development_programming:before {
  content: "\e920";
}

.jdi-content_copywriting:before {
  content: "\e921";
}

.jdi-services:before {
  content: "\e922";
}

.jdi-services_alt:before {
  content: "\e923";
}

.jdi-website_transition:before {
  content: "\e924";
}

.jdi-website_rebrand:before {
  content: "\e925";
}

.jdi-custom_fonts:before {
  content: "\e900";
}

.jdi-e-brochure:before {
  content: "\e901";
}

.jdi-enhanced_homepage:before {
  content: "\e902";
}

.jdi-instagram_integration:before {
  content: "\e903";
}

.jdi-looping_video:before {
  content: "\e904";
}

.jdi-penthouse_page:before {
  content: "\e905";
}

.jdi-popdown:before {
  content: "\e906";
}

.jdi-resident_testimonials:before {
  content: "\e907";
}

.jdi-retail_page:before {
  content: "\e908";
}

.jdi-skylease:before {
  content: "\e909";
}

.jdi-subpage_manager:before {
  content: "\e90a";
}

.jdi-tour_scheduler:before {
  content: "\e90b";
}

.jdi-virtual_tour_manager:before {
  content: "\e90c";
}