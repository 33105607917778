@font-face {
    font-family: 'jonahdigitalicons';
    src:
        url('../fonts/jonahdigitalicons.ttf?uwqlyj') format('truetype'),
        url('../fonts/jonahdigitalicons.woff?uwqlyj') format('woff'),
        url('../fonts/jonahdigitalicons.svg?uwqlyj#jonahicons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="jdi-"]::before,
[class*=" jdi-"]::before {
    font-family: 'jonahdigitalicons';
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$jdi-cookie_banner: "\e90d";
$jdi-dynamic_lead_source_tracking: "\e90e";
$jdi-pricing_availability_integration: "\e90f";
$jdi-interactive_community_map: "\e912";
$jdi-jonah_dni: "\e913";
$jdi-skylease_upgrade: "\e914";
$jdi-skylease_additional_building: "\e915";
$jdi-third_party_availability_embed: "\e916";
$jdi-community_listing_replication: "\e917";
$jdi-premium_theme_design_swap: "\e918";
$jdi-multiple_integration_feeds: "\e919";
$jdi-property_integration_provider_change: "\e91a";
$jdi-floorplan_pdfs: "\e91b";
$jdi-floorplans_featured: "\e91c";
$jdi-website_theme: "\e91d";
$jdi-logo_redraw: "\e91e";
$jdi-design_skinning: "\e91f";
$jdi-development_programming: "\e920";
$jdi-content_copywriting: "\e921";
$jdi-services: "\e922";
$jdi-services_alt: "\e923";
$jdi-website_transition: "\e924";
$jdi-website_rebrand: "\e925";
$jdi-custom_fonts: "\e900";
$jdi-e-brochure: "\e901";
$jdi-enhanced_homepage: "\e902";
$jdi-instagram_integration: "\e903";
$jdi-looping_video: "\e904";
$jdi-penthouse_page: "\e905";
$jdi-popdown: "\e906";
$jdi-resident_testimonials: "\e907";
$jdi-retail_page: "\e908";
$jdi-skylease: "\e909";
$jdi-subpage_manager: "\e90a";
$jdi-tour_scheduler: "\e90b";
$jdi-virtual_tour_manager: "\e90c";

.jdi-cookie_banner {
    &:before {
        content: $jdi-cookie_banner;
    }
}
.jdi-dynamic_lead_source_tracking {
    &:before {
        content: $jdi-dynamic_lead_source_tracking;
    }
}
.jdi-pricing_availability_integration {
    &:before {
        content: $jdi-pricing_availability_integration;
    }
}
.jdi-interactive_community_map {
    &:before {
        content: $jdi-interactive_community_map;
    }
}
.jdi-jonah_dni {
    &:before {
        content: $jdi-jonah_dni;
    }
}
.jdi-skylease_upgrade {
    &:before {
        content: $jdi-skylease_upgrade;
    }
}
.jdi-skylease_additional_building {
    &:before {
        content: $jdi-skylease_additional_building;
    }
}
.jdi-third_party_availability_embed {
    &:before {
        content: $jdi-third_party_availability_embed;
    }
}
.jdi-community_listing_replication {
    &:before {
        content: $jdi-community_listing_replication;
    }
}
.jdi-premium_theme_design_swap {
    &:before {
        content: $jdi-premium_theme_design_swap;
    }
}
.jdi-multiple_integration_feeds {
    &:before {
        content: $jdi-multiple_integration_feeds;
    }
}
.jdi-property_integration_provider_change {
    &:before {
        content: $jdi-property_integration_provider_change;
    }
}
.jdi-floorplan_pdfs {
    &:before {
        content: $jdi-floorplan_pdfs;
    }
}
.jdi-floorplans_featured {
    &:before {
        content: $jdi-floorplans_featured;
    }
}
.jdi-website_theme {
    &:before {
        content: $jdi-website_theme;
    }
}
.jdi-logo_redraw {
    &:before {
        content: $jdi-logo_redraw;
    }
}
.jdi-design_skinning {
    &:before {
        content: $jdi-design_skinning;
    }
}
.jdi-development_programming {
    &:before {
        content: $jdi-development_programming;
    }
}
.jdi-content_copywriting {
    &:before {
        content: $jdi-content_copywriting;
    }
}
.jdi-services {
    &:before {
        content: $jdi-services;
    }
}
.jdi-services_alt {
    &:before {
        content: $jdi-services_alt;
    }
}
.jdi-website_transition {
    &:before {
        content: $jdi-website_transition;
    }
}
.jdi-website_rebrand {
    &:before {
        content: $jdi-website_rebrand;
    }
}
.jdi-custom_fonts {
    &:before {
        content: $jdi-custom_fonts;
    }
}
.jdi-e-brochure {
    &:before {
        content: $jdi-e-brochure;
    }
}
.jdi-enhanced_homepage {
    &:before {
        content: $jdi-enhanced_homepage;
    }
}
.jdi-instagram_integration {
    &:before {
        content: $jdi-instagram_integration;
    }
}
.jdi-looping_video {
    &:before {
        content: $jdi-looping_video;
    }
}
.jdi-penthouse_page {
    &:before {
        content: $jdi-penthouse_page;
    }
}
.jdi-popdown {
    &:before {
        content: $jdi-popdown;
    }
}
.jdi-resident_testimonials {
    &:before {
        content: $jdi-resident_testimonials;
    }
}
.jdi-retail_page {
    &:before {
        content: $jdi-retail_page;
    }
}
.jdi-skylease {
    &:before {
        content: $jdi-skylease;
    }
}
.jdi-subpage_manager {
    &:before {
        content: $jdi-subpage_manager;
    }
}
.jdi-tour_scheduler {
    &:before {
        content: $jdi-tour_scheduler;
    }
}
.jdi-virtual_tour_manager {
    &:before {
        content: $jdi-virtual_tour_manager;
    }
}
